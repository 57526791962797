import React from 'react';
import styles from './styles.module.scss';

const Button = ({ text, children, secondary, inverse, ...props }) => {
    return (
        <button
            {...props}
            className={`${styles.button} ${secondary ? styles.buttonSecondary : ''} ${
                inverse ? styles.buttonInverse : ''
            } px-12 lg:mr-8 py-5 border-none outline-none text-black text-xl font-bold cursor-pointer mb-4 lg:mb-0`}
        >
            {text || children}
        </button>
    );
};

export default Button;
